import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"

import useForm from "../../../../_shared/hooks/useForm"
import { Checkbox, Select, TimePicker } from "antd"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete"
import {
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import moment from "moment"
import { Loader } from "@googlemaps/js-api-loader"

// ----------------------------------------------------------------------------

function StoreFormBottomSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { open, post, onConfirm, volantini, ...sheetProps } = props

  const { Option } = Select

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const initialValues = post ?? {}

  const [formValues, setFormValue, setFormValues, clearForm] =
    useForm(initialValues)
  const format = "HH:mm"
  const [value, setValue] = useState(null)

  const [latAndLng, setLatAndLng] = useState()
  const [place, setPlace] = useState()

  const [markerPosition, setMarkerPosition] = useState()

  const [defaultLocation, setDefaultLocation] = useState()

  dayjs.extend(customParseFormat)
  dayjs.locale("it")
  var utc = require("dayjs/plugin/utc")
  var timezone = require("dayjs/plugin/timezone")

  dayjs.extend(utc)
  dayjs.extend(timezone)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAdX30xF_qUHES2TpXt-Fi4-OQvB71joVQ",
    libraries: ["places"],
  })

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    if (!!formValues?.location) {
      setLatAndLng({
        lat: Number(formValues?.location?.lat),
        lng: Number(formValues?.location?.long),
      })
    }
  }, [formValues])

  useEffect(() => {
    if (open) {
      setFormValues(post ?? {})
    } else {
      clearForm()
    }
  }, [open])

  useEffect(() => {
    setMarkerPosition({ lat: latAndLng?.lat, lng: latAndLng?.lng })

    setDefaultLocation({ lat: latAndLng?.lat, lng: latAndLng?.lng })
  }, [latAndLng])

  useEffect(() => {
    value &&
      geocodeByAddress(value?.label)
        .then((results) => {
          const num =
            results[0]?.address_components?.find((comp) =>
              comp.types?.includes("street_number")
            )?.short_name ?? ""
          const street = results[0]?.address_components?.find((comp) =>
            comp.types?.includes("route")
          )?.short_name
          const city = results[0]?.address_components?.find((comp) =>
            comp.types?.includes("administrative_area_level_3")
          )?.short_name
          const postalCode = results[0]?.address_components?.find((comp) =>
            comp.types?.includes("postal_code")
          )?.short_name
          const province = results[0]?.address_components?.find((comp) =>
            comp.types?.includes("administrative_area_level_2")
          )?.short_name
          setPlace({ street: street + ` ${num}`, city, postalCode, province })
          return getLatLng(results[0])
        })
        .then(({ lat, lng }) => {
          setLatAndLng({ lat, lng })
          console.log("Successfully got latitude and longitude", { lat, lng })
        })
  }, [value])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFormValueChange(key, parent) {
    return function (value) {
      console.log(">>> handleFormValueChange: ", key, value, parent)
      if (parent) {
        const newFormValues = {
          ...formValues,
          [parent]: { ...formValues[parent], [key]: value },
        }
        setFormValues(newFormValues)
        // setFormValue(parent[key], value);
      } else {
        setFormValue(key, value)
      }
    }
  }

  const updateTime = (day, prop) => (value) => {
    const dayBackup = formValues?.openingHours?.[day]

    const updatedContent = {
      ...dayBackup,
      [prop]: dayjs(value).format("HH:mm"),
    }
    let newOpeningHours = formValues?.openingHours
    newOpeningHours[day] = updatedContent
    setFormValue("openingHours", newOpeningHours)
  }

  const updateCheckbox = (day, prop, values) => (value) => {
    const updatedContent = { ...values, [prop]: value }
    const oldOpeningHours = formValues?.openingHours

    const newFormValues = {
      ...formValues,
      openingHours: { ...oldOpeningHours, [day]: updatedContent },
    }
    setFormValues(newFormValues)
  }

  function handleConfirm() {
    onConfirm?.(formValues, { place, latAndLng })
  }
  const mapStyle = {
    height: "300px",
    width: "100%",
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const weekDays = [
    { key: "monday", value: "Lunedí" },
    { key: "tuesday", value: "Martedí" },
    { key: "wednesday", value: "Mercoledí" },
    { key: "thursday", value: "Giovedí" },
    { key: "friday", value: "Venerdí" },
    { key: "saturday", value: "Sabato" },
    { key: "sunday", value: "Domenica" },
  ]

  function renderContinuedTime(day, dayValue) {
    return (
      <div>
        DALLE{"  "}
        <TimePicker
          minuteStep={15}
          hourStep={1}
          format={format}
          defaultValue={dayjs(dayValue?.mStart, "HH:mm")}
          onChange={(e) => updateTime(day.key, "mStart")(e)}
        />
        {"  "}ALLE{"  "}
        <TimePicker
          minuteStep={15}
          hourStep={1}
          format={format}
          defaultValue={dayjs(dayValue?.mStop, "HH:mm")}
          onChange={updateTime(day.key, "mStop")}
        />
      </div>
    )
  }

  function renderNotContinuedTime(day, dayValue) {
    return (
      dayValue && (
        <div>
          <div>
            MATTINA DALLE{"  "}
            <TimePicker
              minuteStep={15}
              hourStep={1}
              format={format}
              defaultValue={dayjs(dayValue?.mStart, "HH:mm")}
              onChange={updateTime(day.key, "mStart")}
            />
            {"  "}ALLE{"  "}
            <TimePicker
              minuteStep={15}
              hourStep={1}
              format={format}
              defaultValue={dayjs(dayValue?.mStop, "HH:mm")}
              onChange={updateTime(day.key, "mStop")}
            />
          </div>
          {"  "}POMERIGGIO DALLE{"  "}
          <TimePicker
            minuteStep={15}
            hourStep={1}
            format={format}
            defaultValue={dayjs(dayValue?.aStart, "HH:mm")}
            onChange={updateTime(day.key, "aStart")}
          />
          {"  "}
          ALLE{"  "}
          <TimePicker
            minuteStep={15}
            hourStep={1}
            format={format}
            defaultValue={dayjs(dayValue?.aStop, "HH:mm")}
            onChange={updateTime(day.key, "aStop")}
          />
        </div>
      )
    )
  }

  function renderDetailsHour(day, values) {
    return (
      <div style={{ marginTop: 20 }}>
        <h3>{day.value}</h3>

        {!values?.isClosed && (
          <Checkbox
            checked={values?.continuedTime}
            onChange={(e) =>
              updateCheckbox(
                day.key,
                "continuedTime",
                values
              )(e?.target?.checked)
            }
          >
            Orario continuato
          </Checkbox>
        )}
        <Checkbox
          checked={values?.isClosed}
          onChange={(e) =>
            updateCheckbox(day.key, "isClosed", values)(e?.target?.checked)
          }
        >
          Chiuso
        </Checkbox>
        {!values?.isClosed &&
          (values?.continuedTime
            ? renderContinuedTime(day, values)
            : renderNotContinuedTime(day, values))}
      </div>
    )
  }

  function renderHours() {
    return (
      <div className="form-control">
        {weekDays.map((day) =>
          renderDetailsHour(day, formValues?.openingHours?.[day?.key])
        )}
      </div>
    )
  }

  function renderNewsPaper() {
    return (
      <div className="form-control">
        <Select
          value={formValues?.linkedVolantino?.name}
          placeholder="Volantino associato"
          optionFilterProp="children"
          showSearch
          onSelect={handleFormValueChange("linkedVolantino")}
          // checked={formValues.disabled}
        >
          {volantini?.map((x, index) => (
            <Option key={x.id} id={index}>
              {x?.name}
            </Option>
          ))}
        </Select>
      </div>
    )
  }

  function renderMap() {
    console.log("default location : ", defaultLocation)
    return (
      isLoaded &&
      !isNaN(defaultLocation?.lat) && (
        <div>
          <GoogleMap
            center={defaultLocation}
            zoom={13}
            mapContainerStyle={mapStyle}
          >
            <MarkerF position={markerPosition} />
          </GoogleMap>
        </div>
      )
    )
  }

  function renderAddress() {
    return (
      <div>
        <div className="form-control">
          <GooglePlacesAutocomplete
            autocompletionRequest={{
              componentRestrictions: {
                country: ["it"], //to set the specific country
              },
            }}
            GooglePlacesDetailsQuery={{ fields: "geometry" }}
            fetchDetails={true}
            selectProps={{
              placeholder: formValues?.address
                ? `${formValues?.address?.street} - ${formValues?.address?.city} (${formValues?.address?.province})`
                : "seleziona indirizzo",
              value,
              onChange: setValue,
            }}
            apiKey="AIzaSyAdX30xF_qUHES2TpXt-Fi4-OQvB71joVQ"
          />
        </div>
        <div className="form-control">{latAndLng && renderMap()}</div>
      </div>
    )
  }

  function renderServices() {
    return (
      <div>
        <Checkbox
          style={{ marginBottom: 30, textTransform: "uppercase" }}
          onChange={(e) =>
            handleFormValueChange(
              "pharmacy",
              "services"
            )(e.target.checked ? "VERO" : "FALSO")
          }
          checked={formValues.services?.pharmacy === "VERO"}
        >
          Farmaci Veterinari
        </Checkbox>
        <Checkbox
          style={{ marginBottom: 30, textTransform: "uppercase" }}
          onChange={(e) =>
            handleFormValueChange(
              "grooming",
              "services"
            )(e.target.checked ? "VERO" : "FALSO")
          }
          checked={formValues.services?.grooming === "VERO"}
        >
          Toelettatura
        </Checkbox>
        <Checkbox
          style={{ marginBottom: 30, textTransform: "uppercase" }}
          onChange={(e) =>
            handleFormValueChange(
              "aquarium",
              "services"
            )(e.target.checked ? "VERO" : "FALSO")
          }
          checked={formValues.services?.aquarium === "VERO"}
        >
          Acquariologia
        </Checkbox>
        <Checkbox
          style={{ marginBottom: 30, textTransform: "uppercase" }}
          onChange={(e) =>
            handleFormValueChange(
              "balance",
              "services"
            )(e.target.checked ? "VERO" : "FALSO")
          }
          checked={formValues.services?.balance === "VERO"}
        >
          Controllo del peso
        </Checkbox>
        <Checkbox
          style={{ marginBottom: 30, textTransform: "uppercase" }}
          onChange={(e) =>
            handleFormValueChange(
              "laundry",
              "services"
            )(e.target.checked ? "VERO" : "FALSO")
          }
          checked={formValues.services?.laundry === "VERO"}
        >
          Pet laundry
        </Checkbox>
      </div>
    )
  }

  return (
    <BottomSheet
      className={props.className}
      open={open}
      snapPoint={80}
      title={post?.id ? "Modifica store" : "Nuovo store"}
      onConfirm={handleConfirm}
      {...sheetProps}
    >
      <Checkbox
        style={{ marginBottom: 30 }}
        onChange={(e) => handleFormValueChange("disabled")(e.target.checked)}
        checked={formValues.disabled}
      >
        Disabilitato
      </Checkbox>
      <Input
        className="form-control"
        label="Nome"
        value={formValues.name}
        onChange={handleFormValueChange("name")}
      />
      <Input
        className="form-control"
        label="Codice negozio"
        value={formValues.code}
        onChange={handleFormValueChange("code")}
      />
      <Input
        className="form-control"
        label="Numero di telefono"
        value={formValues.phone}
        onChange={handleFormValueChange("phone")}
        type="number"
      />
      <h2>INDIRIZZO</h2>
      {formValues && renderAddress()}
      <h2>SERVIZI OFFERTI</h2>
      {formValues && renderServices()}
      <h2>VOLANTINO ASSOCIATO</h2>
      {formValues && renderNewsPaper()}
      <h2>ORARI</h2>
      {formValues && renderHours()}
    </BottomSheet>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

StoreFormBottomSheet.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  post: PropTypes.object,
  onConfirm: PropTypes.func.isRequired,
}

StoreFormBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const StoreFormBottomSheetWithStyle = styled(StoreFormBottomSheet)`
  & {
    .sheet-content {
      max-width: 500px;

      .form-control {
        margin-bottom: 20px;
        margin-left: 20px;

        width: 500px;
        max-width: 500px;
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StoreFormBottomSheetWithStyle
