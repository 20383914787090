import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"
import { DatePicker, Tabs } from "antd"

import BottomSheet from "../../../../_shared/components/BottomSheet"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import AppTextarea from "../../../../_shared/components/FormItems/AppTextarea"
import useForm from "../../../../_shared/hooks/useForm"
import moment from "moment"

// ----------------------------------------------------------------------------

const { TabPane } = Tabs

// ----------------------------------------------------------------------------

function _NotificationsBottomSheet(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { className, onCancel, onConfirm, defaultValues, open } = props

  // -------------------------------------
  // Hooks (e.g. useState, ...)
  // -------------------------------------

  const [form, setOne, setMany, clearForm] = useForm()

  // -------------------------------------
  // Memoized values
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  useEffect(() => {
    !open && clearForm()
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    defaultValues && setMany(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleConfirm() {
    onConfirm && onConfirm(form)
  }

  function handleChange(field) {
    return function (value) {
      setOne(field, value)
    }
  }
  function getViewingPeriod() {
    return form?.scheduledAt ? moment.utc(form?.scheduledAt) : null
  }
  // -------------------------------------
  // Component local variables
  // -------------------------------------

  return (
    <BottomSheet
      snapPoint={80}
      open={open}
      onCancel={onCancel}
      onConfirm={handleConfirm}
      className={`${className}`}
      title={form.id ? "Modifica notifica" : "Nuova notifica"}
    >
      <AppInput
        className="field"
        value={form?.title}
        onChange={handleChange("title")}
        label="Titolo"
      />
      <AppTextarea
        showNow={false}
        className="field"
        value={form?.body}
        onChange={handleChange("body")}
        label="Testo"
      />
      <h3>Schedula orario di invio notifica</h3>
      <DatePicker
        format="YYYY-MM-DD HH:mm"
        showTime
        value={getViewingPeriod()}
        onOk={handleChange("scheduledAt")}
        onChange={(a, dateString) => handleChange("scheduledAt")(dateString)}
        showNow
      />
    </BottomSheet>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_NotificationsBottomSheet.propTypes = {
  className: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  defaultValues: PropTypes.object,
  open: PropTypes.bool,
}

_NotificationsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const NotificationsBottomSheet = styled(_NotificationsBottomSheet)`
  & {
    .field {
      width: 300px;
      margin-bottom: 20px;
    }
  }
`
// ----------------------------------------------------------------------------

export default NotificationsBottomSheet
